import {UPDATELOGINPOPOVERVISIBILITY, UPDATEUSER, UPDATELOGINPOPOVERID,UPDATEERRORMESSAGE} from '../actions/types';


let initialState={
  loginPopoverVisible : false,
  user : undefined,
  errorMessage : "",
  anchorElId : ""
}
function loginReducer(state = initialState, action){
    switch(action.type){
      case UPDATELOGINPOPOVERVISIBILITY:
       return{...state, loginPopoverVisible : action.payload.value}
      case UPDATEUSER:
        return{...state, user : action.payload.value}
      case UPDATEERRORMESSAGE:
        return{...state, errorMessage : action.payload.value}
      case UPDATELOGINPOPOVERID:
        return{...state, anchorElId : action.payload.value}
        
      default:
        return state;
    }
  }
  export default loginReducer