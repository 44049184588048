import React, { useState } from 'react';
import { connect } from 'react-redux';
import {Grid,Typography,useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import{setActiveView} from '../../actions/viewActions'
import {pushToDB} from '../../helper';
import firebase from '../../firebase';

function ViewSwitcher(props){
    let theme = useTheme();
    let sm = useMediaQuery(theme.breakpoints.down("sm"));
    let xs = useMediaQuery(theme.breakpoints.down("xs"));

    const [db_uploading,setDb_uploading] = useState(false);

    const activeStyle = {
        cursor:"pointer",
        textAlign:"center",
        padding: "16px 0",
        border: "solid 1px",
        borderColor:"#f4000040",
        backgroundColor:"#1F1F1F",
        boxSizing:"border-box",
    }
    const inactiveStyle = {
        cursor:"pointer",
        textAlign:"center",
        padding: "16px 0",
        border: "solid 1px rgba(255,255,255,0.4)",
        boxSizing:"border-box",
    }
    const saveButtonStyle = {
        cursor:"pointer",
        textAlign:"center",
        padding: "16px 0",
        border: "solid 1px rgba(255,255,255,0.4)",
        color : "green",
        boxSizing:"border-box",
    }
    const savingButtonStyle = {
        color : "#00800050",
        textAlign:"center",
        padding: "16px 0",
        border: "solid 1px rgba(255,255,255,0.2)",
        boxSizing:"border-box",
    } 
        return (
            <Grid container direction="row" justify="center" alignContent="center">
                <Grid item xl={4} lg={4} md={3} sm={3} xs={3}
                style={props.view.activeView === "list" ? activeStyle : inactiveStyle}
                onClick={()=>{
                   props.setActiveView({value:"list"})
                }}
                >
                    <Typography
                    variant= {xs ? "subtitle1" : (sm ? "h4" : "h3")}
                    color="secondary"
                    >
                        List View
                    </Typography>
                </Grid>
                <Grid item xl={4} lg={4} md={3} sm={3} xs={3}
                style={props.view.activeView === "tour" ? activeStyle : inactiveStyle}
                onClick={()=>{
                    props.setActiveView({value:"tour"})
                 }}
                >
                     <Typography
                        variant= {xs ? "subtitle1" : (sm ? "h4" : "h3")}
                        color="secondary"
                        >
                            Tour View
                    </Typography>      
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={3} xs={3}
                style={db_uploading ? savingButtonStyle : saveButtonStyle}
                onClick = {()=>{
                    if(!db_uploading){
                        setDb_uploading(true)
                        pushToDB(props.baeder.baeder).then(()=>{
                            setDb_uploading(false)
                        })
                    }
                    
                }}
                >
                     <Typography
                        variant= {xs ? "subtitle1" : (sm ? "h4" : "h3")}
                        >
                            save
                    </Typography>      
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={3} xs={3}
                style={inactiveStyle}
                onClick = {()=>{
                    firebase.auth().signOut();
                }}
                >
                     <Typography
                        color = "primary"
                        variant= {xs ? "subtitle1" : (sm ? "h4" : "h3")}
                        >
                            Log Out
                    </Typography>      
                </Grid>
            </Grid>
               
        );
}
let mapStateToProps = state => ({
    view : state.view,
    baeder : state.baeder
});
let mapDispatchToProps = {
    setActiveView : setActiveView
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewSwitcher);