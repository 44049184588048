
import {UPDATEUSER,UPDATELOGINPOPOVERVISIBILITY,UPDATELOGINPOPOVERID,UPDATEERRORMESSAGE} from './types';
export const updateUser = (value) => dispatch => {
    dispatch(
        {
            type: UPDATEUSER,
            payload : value
        }
    );      
}
export const setLoginPopoverVisibility = (value) => dispatch => {
    dispatch(
        {
            type: UPDATELOGINPOPOVERVISIBILITY,
            payload : value
        }
    );      
}
export const updateLoginPopoverID = (value) => dispatch => {
    dispatch(
        {
            type: UPDATELOGINPOPOVERID,
            payload : value
        }
    );      
}
export const updateLoginErrorMessage = (value) => dispatch => {
    dispatch(
        {
            type: UPDATEERRORMESSAGE,
            payload : value
        }
    );      
}