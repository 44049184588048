import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PanoInstance from "../../container/PanoInstance";
import ToolBar from "../../container/ToolBar";
import {Grid} from '@material-ui/core'
import ViewSwitcher from '../../components/ViewSwitcher'
import ListView from '../../container/ListView';
import TourView from '../../container/TourView';
import axios from 'axios';
import convert from 'xml-js';
import {mergeSort,getBeaderFromDB} from '../../helper'
import {setBaeder} from '../../actions/badActions';


function AppSite(props) {
    useEffect(async()=>{
        function getPos(str, subStr, i) {
            return str.split(subStr, i).join(subStr).length;
        }
        function parseBaeder(xml,db_beader){
            let baeder = [];
            xml.elements[0].elements.forEach((element)=>{
                if(element.name === "panorama"){
                    let userData = element.elements.filter(subelement => subelement.name === "userdata")[0];
                    let str = userData.attributes.title;
                    let underscoreCount = str.split('_').length-1;
                    let badNr = str.substring(getPos(str,"_",underscoreCount-1)+1,str.lastIndexOf("_"));
                    if(badNr !== "" && (badNr.startsWith("M") || (badNr.startsWith("H") && !badNr.startsWith("Hohen")) || !isNaN(parseInt(badNr)))){
                        if(baeder.findIndex(bad => bad.id === badNr) === -1){
                            let db_bad = db_baeder.filter(singleDbBad => singleDbBad.id === badNr)[0];
                            baeder.push({id:badNr,node:element.attributes.id,link:db_bad ? db_bad.link || "" : ""})
                        }
                    }
                    }
            })
            return baeder;
        }
        async function getBaeder(db_beader){
            let xmlRes = await axios.get("https://inhaus.360ty.cloud/pano.xml");
            let xml = JSON.parse(convert.xml2json(xmlRes.data));
            console.log(xml)
            let baeder = parseBaeder(xml,db_beader);
            let sorted_baeder = mergeSort(baeder);
            console.log(sorted_baeder);
            return sorted_baeder;
        }
        let db_baeder = await getBeaderFromDB();
        let baeder = await getBaeder(db_baeder);
        props.setBaeder({value: baeder});
    },[])
        return (
            <Grid container direction = "column" spacing={1} style={{flexWrap:"nowrap"}}>
                <Grid item>
                    <ViewSwitcher/>
                </Grid>
                <Grid item>
                    {props.view.activeView === "list"
                    ?
                    <ListView/>
                    :
                    <TourView/>
                    }
                </Grid>

            </Grid>
        );
}
let mapStateToProps = state => ({
    view:state.view
});
let mapDispatchToProps = {
    setBaeder : setBaeder
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppSite);