import {SETACTIVEBAD,SETBAEDER,UPDATEBAD} from '../actions/types';
let initialState={
    activeView : 0,
    baeder:[]
}
function badReducer(state = initialState, action){
    switch(action.type){
        case SETACTIVEBAD:
            return{...state, activeBad : action.payload.value}
        case SETBAEDER:
            return{...state, baeder : action.payload.value}
        case UPDATEBAD:
            let badIndex = state.baeder.findIndex(bad=> bad.id === action.payload.id);
            let newBaeder = {...state.baeder};
            newBaeder[badIndex][action.payload.key] = action.payload.value;
            return{
                ...state,
                beader : newBaeder
            }
        default:
            return state;
    }
  }
  export default badReducer