import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Grid, ThemeProvider,Divider} from "@material-ui/core";
import {headerTheme} from '../../themes';
import SharedHeader from '../../components/ViewSwitcher'

class ToolBar extends Component {
    render() {
        return (
            <ThemeProvider theme={headerTheme}>
             
                <Grid container spacing = {1} direction="column" style={{padding:"16px"}}>
                    <Grid item>
                        <SharedHeader/>
                    </Grid>
                    <Grid item style={{alignSelf:"center"}}>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}
let mapStateToProps = state => ({

});

export default connect(
    mapStateToProps,
)(ToolBar);