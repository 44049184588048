export const UPDATEBASEPATH = "UPDATEBASEPATH";
export const UPDATEBASEPATHINPUT = "UPDATEBASEPATHINPUT";
export const BASEPATHUPDATED = "BASEPATHUPDATED";
export const UPDATEINSTANCE = "UPDATEINSTANCE";
export const UPDATENODE = "UPDATENODE";
export const UPDATEPANOREADY = "UPDATEPANOREADY";
export const UPDATECURRENTNODE = "UPDATECURRENTNODE";

export const UPDATELOGINPOPOVERVISIBILITY = "UPDATELOGINPOPOVERVISIBILITY";
export const UPDATELOGINPOPOVERID = "UPDATELOGINPOPOVERID";
export const UPDATEUSER = "UPDATEUSER";
export const UPDATEERRORMESSAGE = "UPDATEERRORMESSAGE";

export const SETACTIVEVIEW = "SETACTIVEVIEW";

export const SETACTIVEBAD = "SETACTIVEBAD";
export const SETBAEDER = "SETBAEDER";
export const UPDATEBAD = "UPDATEBAD";