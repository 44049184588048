import React, {  } from 'react';
import { connect } from 'react-redux';
import {DataGrid} from '@material-ui/data-grid';
import {ThemeProvider,useMediaQuery} from '@material-ui/core';
import {dataGridTheme} from '../../themes';
import {updateBad} from '../../actions/badActions'
import './index.css';
import {tour} from '../../pano'
import { useTheme } from '@material-ui/core/styles';

function ListView(props){
    let theme = useTheme();
    let sm = useMediaQuery(theme.breakpoints.down("sm"));
    let xs = useMediaQuery(theme.breakpoints.down("xs"));
    const columns = [
        {field:'id', headerName: 'BadNr', width:"25%", sortable: true},
        {field:'node', headerName: 'Node', width:"25%"},
        {field:'link', headerName: 'Oxomi Link',  width:"50%", sortable: true, editable:true},
    ]
    const rows = props.baeder.baeder.map((bad)=>{
        return {...bad,width:"100%"}
    })
        return (
            <ThemeProvider theme={dataGridTheme}>
                <div style={{ height: `calc(${sm && props.view.activeView === "tour" ? 50 : 100}vh - ${xs ? 72 : (sm ? 84 : 100)}px)`, width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} 
                    onCellValueChange={(e)=>{
                        console.log(e);
                        props.updateBad(e.id,e.field,e.value)
                    }}
                    onCellClick={(e)=>{
                        if(e.field === "node" && props.view.activeView==="tour"){
                            tour.pano.openNext(`{${e.value}}`)
                        }
                    }}
                    onCellEnter={(e)=>{
                        if(e.field === "node" && props.view.activeView==="tour"){
                         e.element.style.cursor = "pointer";
                        }
                    }}
                    />
                </div>
            </ThemeProvider>
        );
}
let mapStateToProps = state => ({
    baeder : state.baeder,
    view : state.view
});
let mapDispatchToProps = {
    updateBad : updateBad
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListView);