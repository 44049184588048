import {SETACTIVEVIEW} from '../actions/types';
let initialState={
    activeView : "list",
}
function viewReducer(state = initialState, action){
    switch(action.type){
        case SETACTIVEVIEW:
            return{...state, activeView : action.payload.value}
     
        default:
            return state;
    }
  }
  export default viewReducer