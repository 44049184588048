import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {updateInstance,setPanoReady,updateCurrentNode,updateBasepathInput,updateBasepath,updateNode} from '../../actions/panoParamActions';
import {tour, initTourVar, initTour, addTourListener,reloadTour,afterPanoLoad} from '../../pano.js';


function PanoInstance(props){
    const setListeners = () =>{
        let beforeNodeChangeListener = () =>{
            props.setPanoReady({value:false});    
        }
        let changeNodeListener = () =>{
            props.setPanoReady({value:true});    
            props.updateCurrentNode({value:tour.pano.getCurrentNode()});                
            if(props.pano_params.basepath !== tour.pano.getBasePath()){
                props.updateBasepath({value:tour.pano.getBasePath()})
                props.updateBasepathInput({value:tour.pano.getBasePath()})

            }   
        }
        let afterPanoLoadCallback = () =>{
            props.updateCurrentNode({value:tour.pano.getCurrentNode()});                
            props.setPanoReady({value:true});    
        }
        addTourListener("beforechangenode",beforeNodeChangeListener);
        addTourListener("changenode",changeNodeListener);
        afterPanoLoad(afterPanoLoadCallback);

    }

        useEffect(()=>{
                try{
                    let params = {basepath:props.pano_params.basepath || "https://lechwinter.360ty.cloud/",node:props.pano_params.node, yDim : "16:9"}               
                    if(!tour){
                    initTourVar("360ty_container");
                    initTour(params);
                    tour.onReloadFinished = ()=>{
                        setListeners()
                        props.updateInstance(tour);        
                        props.updateBasepath({value:tour.pano.getBasePath()})
                        props.updateBasepathInput({value:tour.pano.getBasePath()})
                        let node = tour.pano.getCurrentNode();
                        props.updateNode({value:parseInt(node.substring(node.indexOf("node")+4,node.length-1))})
                    }
                    setListeners()

                    }else{
                        reloadTour(params);
                    }
                    
                    props.updateInstance(tour);        
                }catch(err){
                    console.log(err)
                }
                       

        },[props.pano_params.basepath])


        return(
            <div id ="instance_container" style={{width:"100%"}}>
                <div data-tour={props.pano_params.basepath} id="360ty_container" style={{width:"100%"}}></div>
            </div>
        )
}
let mapStateToProps = state => ({
    pano_params : state.pano_params,
});
let mapDispatchToProps = {
    updateBasepath : updateBasepath,
    updateBasepathInput : updateBasepathInput,
    updateInstance : updateInstance,
    updateCurrentNode : updateCurrentNode,
    setPanoReady : setPanoReady,
    updateNode : updateNode
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PanoInstance);