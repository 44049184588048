
import {SETACTIVEVIEW} from './types';
export const setActiveView = (value) => dispatch => {
    dispatch(
        {
            type: SETACTIVEVIEW,
            payload : value
        }
    );      
}
