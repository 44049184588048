export var tour;
export const initTourVar = (id) =>{
    tour = new window.Pano_360ty(id); 
} 
export const initTour = (params) =>{
    tour.setBasePath(params.basepath);
    tour.setStartNode(parseInt(params.node));
    tour.setDimensions("100%",params.yDim);
    tour.setHorizontalAlignment("center");
    tour.setSingleImage(false);
    tour.setShareButtonVisibility(false);
    tour.setImpressumVisibility(true);
    tour.setDimensions_tablet("100%","16:9");
    tour.setHorizontalAlignment_tablet("center");
    tour.setSingleImage_tablet(false);
    tour.setShareButtonVisibility_tablet(false);
    tour.setImpressumVisibility_tablet(false);
    tour.setDimensions_mobile("100%","16:9");
    tour.setHorizontalAlignment_mobile("center");
    tour.setSingleImage_mobile(false);
    tour.setShareButtonVisibility_mobile(false);
    tour.setImpressumVisibility_mobile(false);
    tour.useBasepathSkin();
    tour.init();
}
export const addTourListener = (listener, callback) => {
    let pano_ready_interval = setInterval(()=>{
        if(tour.pano && tour.pano.getIsLoaded()){
            clearInterval(pano_ready_interval);
            tour.pano.addListener(listener,callback)
            console.log("added listener")
        }
    },10)
}
         
export const reloadTour = (params) =>{
    if(tour && tour.pano){
        tour.setBasePath(params.basepath);
        tour.setStartNode(parseInt(params.node));
        tour.setDimensions("100%",params.yDim);
        tour.reload();
  
    }
}
export const afterPanoLoad = (callback) =>{
    let panoInterval = setInterval(()=>{
        if(tour && tour.pano && tour.pano.getIsLoaded){
            clearInterval(panoInterval);
            callback()
        }
    },10)
}