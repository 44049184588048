import React, { useState } from 'react';
import { connect } from 'react-redux';
import {Grid,Popover,TextField,ThemeProvider,InputAdornment,InputLabel,IconButton,OutlinedInput,FormControl,Typography,Button } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import{loginTheme} from '../../themes';
import firebase from '../../firebase';
import{updateUser,updateLoginErrorMessage} from '../../actions/loginActions'

const auth = firebase.auth();
function LoginSite(props) {
console.log(auth.currentUser)
    const [showPassword,setShowPassword] = useState(false);
    const [password,setPassword] = useState("");
    const [email,setEmail] = useState("");
    const [forgotEmail,setForgotEmail] = useState(email);
    const [resetMessage,setResetMessage] = useState("");
    const [resetPopoverOpen,setResetPopoverOpen] = useState(false);

    const login = () =>{
        auth.signInWithEmailAndPassword(email.trim(), password).then((user)=>{
            props.updateLoginErrorMessage({value:""});
            props.updateUser(user);
        }).catch((err)=>{
            props.updateLoginErrorMessage({value:err.message})
        });
    }
    const resetPw = () =>{
        setResetMessage("");
        auth.sendPasswordResetEmail(forgotEmail).then(()=>{
            setResetMessage(`Email to reset password sent to: ${forgotEmail}`)
        }).catch((err)=>{
            setResetMessage(err.message)
        })
    }
        return (
            <ThemeProvider theme={loginTheme}>
                <Grid container alignContent = "center" justify="center" style={{height:"100%"}}>
                    <Grid item style={{height:"100%"}}>
                        <Grid id="firebaseui-auth-container" container direction="column" style={{height:"100%"}} justify="center" alignContent="center">
                            <Grid item style={{backgroundColor:"#1F1F1F",padding:"32px",paddingTop:"16px",textAlign:"center"}}>
                                <Typography
                                variant = "h3"
                                color="secondary"
                                >
                                    Login

                                </Typography>
                             </Grid>
                            <Grid item style={{backgroundColor:"#1F1F1F",padding:"16px"}}>
                                <TextField
                                variant = "outlined"
                                label = "e-mail"
                                value={email}
                                onChange={(e,val) =>{
                                    setEmail(e.target.value ||val);
                                }}
                                onKeyPress={(e)=>{
                                    if(e.key === "Enter"){
                                        login()
                                    }
                                }}
                                >
                                
                                </TextField>
                            </Grid>
                            <Grid item style={{backgroundColor:"#1F1F1F",padding:"16px",paddingTop:0}}>
                            <FormControl variant="filled">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                id="outlined-adornment-password"
                                variant = "outlined"
                                type= {showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e,val) =>{
                                    setPassword(e.target.value ||val);
                                }}
                                onKeyPress={(e)=>{
                                    if(e.key === "Enter"){
                                        login()
                                    }
                                }}
                                labelWidth={70}
                                endAdornment={
                                    <InputAdornment position="end"
                                    >
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{setShowPassword(!showPassword)}}
                                        edge="end"
                                        color = "secondary"
                                      >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                >

                                </OutlinedInput>
                            </FormControl>
                            </Grid>
                            {props.login.errorMessage !== ""
                            ?
                            <Grid item style={{backgroundColor:"#1F1F1F",padding:"16px",paddingTop:0,maxWidth:"50%"}}>
                                
                                <Typography
                                variant="body1"
                                color="primary"
                                >{props.login.errorMessage}</Typography>
                            </Grid>
                            :
                            <div/>
                            }
                            <Grid item style={{backgroundColor:"#1F1F1F",padding:"16px",paddingTop:0}}>
                                <Grid container>
                                    <Grid item >
                                        <Button
                                        variant = "outlined"
                                        color="primary"
                                        onClick = {login}
                                        >
                                            login
                                        </Button>
                                    </Grid>
                                    <Grid item style={{backgroundColor:"#1F1F1F",padding:"16px",paddingTop:0}}>
                                        <Button
                                        id="reset-button"
                                        variant = "outlined"
                                        color="primary"
                                        onClick= {()=>{setResetPopoverOpen(true)}}
                                        >
                                            reset Password
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Popover
                                anchorEl={document.getElementById("reset-button")}
                                open={resetPopoverOpen}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose = {()=>{setResetPopoverOpen(false)}}
                            >
                                <Grid container direction = "column" spacing = {1} style={{backgroundColor:"#1F1F1F",padding:"16px"}}>
                                    <Grid item>
                                        <TextField
                                            variant = "outlined"
                                            label = "Account-Email"
                                            value = {forgotEmail}
                                            onChange = {(e)=>{
                                                setForgotEmail(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                        variant = "outlined"
                                        color="primary"
                                        onClick= {resetPw}
                                        >
                                            send reset mail
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {resetMessage !== ""
                                        ?
                                        <Typography
                                        color = "secondary"
                                        >
                                            {resetMessage}
                                        </Typography>
                                        :
                                        <div/>
                                        }
                                        
                                    </Grid>
                                </Grid>
                            </Popover>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
}

let mapStateToProps = state => ({
    login: state.login
})
let mapDispatchToProps = {
    updateUser : updateUser,
    updateLoginErrorMessage : updateLoginErrorMessage
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginSite);