import { createMuiTheme } from '@material-ui/core/styles';
let primary="#F40000";
let secondary="#FFFFFF";
let fontLight="#c9c9c9";
let backgroundDark="#1f1f1f";
let backgroundMid="#2b2b2b";
let backgroundLight="#404040";

export const main = createMuiTheme({
    palette: {
        primary: {
            main: primary,
        },
        secondary: {
            main : secondary
        },
    },
    typography:{
        fontFamily:[
            "'Raleway'",
            "sans-serif"
        ].join(","),
    },
});
export const headerTheme = createMuiTheme({
    palette:{
        primary:{
            main: primary
        },
        secondary:{
            main: secondary
        }
    },
    typography:{
        fontFamily:[
            "'Raleway'",
            "sans-serif"
        ].join(","),
    },
    overrides:{
        MuiTypography:{
            h5:{

            }
        },
        MuiInputBase:{
            input:{
                borderColor: secondary,
                backgroundColor:  backgroundMid,
                color: secondary
            }
        },
        MuiFormLabel:{
            root:{
                color: secondary+"50",
            }
        },
        MuiButton:{
            outlined:{
                borderColor: secondary+"50"
            }
        }
    }
});
export const screenshotPanelTheme = createMuiTheme({
    palette:{
        primary:{
            main: primary
        },
        secondary:{
            main: secondary
        }
    },
    typography:{
        fontFamily:[
            "'Raleway'",
            "sans-serif"
        ].join(","),
    },
    overrides:{
        MuiTypography:{
            h5:{

            }
        },
        MuiInputBase:{
            input:{
                borderColor: secondary,
                backgroundColor:  backgroundMid,
                color: secondary
            }
        },
        MuiFormLabel:{
            root:{
                color: secondary+"50",
            }
        },
        MuiButton:{
            outlined:{
                borderColor: secondary+"50"
            }
        },
        MuiCheckbox:{
            root:{
                color: secondary+"50"
            }
        },
    }
});
export const loginTheme = createMuiTheme({
    palette:{
        primary:{
            main: primary
        },
        secondary:{
            main: secondary
        }
    },
    typography:{
        fontFamily:[
            "'Raleway'",
            "sans-serif"
        ].join(","),
    },
    overrides:{
        MuiButton:{
            outlined:{
                borderColor: secondary+"50"
            }
        },
        MuiInputBase:{
            input:{
                borderColor: secondary,
                backgroundColor:  backgroundMid,
                color: secondary
            }
        },
        MuiFormLabel:{
            root:{
                color: secondary+"50",
            }
        },
    }
});
export const dataGridTheme = createMuiTheme({
    palette:{
        primary:{
            main: primary
        },
        secondary:{
            main: secondary
        }
    }, 
    typography:{
        fontFamily:[
            "'Raleway'",
            "sans-serif"
        ].join(","),
    },

})
export const sliderTheme = createMuiTheme({
    palette:{
        primary:{
            main: primary
        },
        secondary:{
            main: secondary
        }
    },
    typography:{
        fontFamily:[
            "'Raleway'",
            "sans-serif"
        ].join(","),
    },
    overrides:{
     MuiSlider:{
         thumb:{
             color: primary
         },
         markLabel:{
             color:"rgb(115,113,113)"
         },
         markLabelActive:{
             color:secondary
         }
     }
    }
});

export const ScreenshotsTheme = createMuiTheme({
    palette:{
        primary:{
            main: primary
        },
        secondary:{
            main: secondary
        }
    },
    typography:{
        fontFamily:[
            "'Raleway'",
            "sans-serif"
        ].join(","),
    },
    overrides:{
        MuiTypography:{
            h5:{

            }
        },
        MuiInputBase:{
            input:{
                borderColor: secondary,
                backgroundColor:  backgroundMid,
                color: secondary
            }
        },
        MuiFormLabel:{
            root:{
                color: secondary+"50",
            }
        },
        MuiButton:{
            outlined:{
                borderColor: secondary+"50"
            }
        },
        MuiCheckbox:{
            root:{
                color: secondary+"50"
            }
        },
        MuiPopover:{
            paper:{
                backgroundColor: backgroundDark,
                overflow:"hidden"
            }
        }
    }
});
export const outputTheme = createMuiTheme({
    palette: {
        primary :{
            main: primary
        },
        secondary: {
            main: secondary
        }
    },
    typography:{
      fontFamily:[
          "'Raleway'",
          "sans-serif"
      ].join(","),
  },
    overrides:{
      MuiPaper:{
          root:{
              backgroundColor:backgroundLight
          }
      },
      MuiTypography:{
          h4:{
              color: secondary,
              textAlign: "center",
              margin:"0.5em"
          }
      },
      
    }
  });
  export const uploadTheme = createMuiTheme({
    palette: {
        primary :{
            main: primary
        },
        secondary: {
            main: secondary
        }
    },
    typography:{
      fontFamily:[
          "'Raleway'",
          "sans-serif"
      ].join(","),
  },
    overrides:{
        MuiInputBase:{
            input:{
                borderColor: secondary,
                backgroundColor:  backgroundMid,
                color: secondary
            }
        },
        MuiFormLabel:{
            root:{
                color: secondary+"50",
            }
        },
    }
  });
  export const datePickerTheme = createMuiTheme({
    palette: {
        primary :{
            main: primary
        },
        secondary: {
            main: secondary
        }
    },
    typography:{
      fontFamily:[
          "'Raleway'",
          "sans-serif"
      ].join(","),
  },
    overrides:{
        MuiInputBase:{
            input:{
                borderColor: secondary,
                backgroundColor:  backgroundMid,
                color: secondary
            }
        },
        MuiFormLabel:{
            root:{
                color: secondary+"50",
            }
        },
        MuiFormControlLabel:{
            label:{
                color : secondary
            }
        }
    }
  });
  