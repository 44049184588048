import {UPDATEBASEPATH, UPDATEBASEPATHINPUT, BASEPATHUPDATED, UPDATEINSTANCE, UPDATENODE, UPDATEPANOREADY,UPDATECURRENTNODE} from './types';

export const updateBasepath = (value) => dispatch => {
    dispatch(
        {
            type: UPDATEBASEPATH,
            payload : value
        }
    );      
}
export const updateBasepathInput = (value) => dispatch => {
    dispatch(
        {
            type: UPDATEBASEPATHINPUT,
            payload : value
        }
    );      
}
export const setBasepathUpdated = (value) => dispatch => {
    dispatch(
        {
            type: BASEPATHUPDATED,
            payload : value
        }
    );      
}
export const updateInstance = (value) => dispatch => {
    dispatch(
        {
            type: UPDATEINSTANCE,
            payload : value
        }
    );      
}
export const updateNode = (value) => dispatch => {
    dispatch(
        {
            type: UPDATENODE,
            payload : value
        }
    );      
}
export const setPanoReady = (value) => dispatch => {
    dispatch(
        {
            type: UPDATEPANOREADY,
            payload : value
        }
    );      
}
export const updateCurrentNode = (value) => dispatch => {
    dispatch(
        {
            type: UPDATECURRENTNODE,
            payload : value
        }
    );      
}