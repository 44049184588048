import firebase from "firebase/app";
import "firebase/auth"; 
import "firebase/firestore";
import "firebase/analytics";

var firebaseConfig = {
    apiKey: "AIzaSyCkXK-neBoGwLaSdiN5g84iZqgejG5D4ZE",
    authDomain:"inhaus-baeder-db.firebaseapp.com",
    projectId: "inhaus-baeder-db",
    storageBucket: "inhaus-baeder-db.appspot.com",
    messagingSenderId:"895672220722",
    appId: "1:895672220722:web:a866221d524cce7eb0102b",
    measurementId: "G-54FSZZ3J6J"
  };
console.log(firebaseConfig)
 firebase.initializeApp(firebaseConfig);
 firebase.firestore();
firebase.analytics();
 firebase.auth();
 export default firebase;