import './App.css';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import firebase from './firebase';
import LoginSite from './sites/LoginSite';
import AppSite from './sites/AppSite';
import {updateUser} from './actions/loginActions'

const db = firebase.firestore();

function App(props) {

  const authListener = () =>{
    firebase.auth().onAuthStateChanged(async(user)=>{

      if(user){
        props.updateUser({value:user})
        
        }else{
      props.updateUser({value:null})

        }
    })
  }
  useEffect(async()=>{
    await authListener()
  },[])
  return (
    <div style={{height:"100%"}}>
      {props.login.user
      ?
      <AppSite/>
      :
      <LoginSite/>
    }
    </div>
  );
}
let mapStateToProps = state => ({
 instance : state.pano_params.instance,
  login:state.login
})

let mapDispatchToProps = {
  updateUser : updateUser
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
