import firebase from './firebase';
const db = firebase.firestore();
console.log(db.collection("baeder"))
export const getRandomID= ()=>{
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

  var length = 9;

  var str = '';
  for (var i = 0; i < length; i++) {
      str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}
 const  merge = (left, right) => {
  const resArr = [];
  let leftIdx = 0;
  let rightIdx = 0;

  while (leftIdx < left.length && rightIdx < right.length) {
    left[leftIdx].id < right[rightIdx].id
      ? resArr.push(left[leftIdx++])
      : resArr.push(right[rightIdx++]);
  }
  return [...resArr, ...left.slice(leftIdx), ...right.slice(rightIdx)];
};

export const mergeSort = arr =>
  arr.length <= 1
    ? arr
    : merge(
        mergeSort(arr.slice(0, Math.floor(arr.length / 2))),
        mergeSort(arr.slice(Math.floor(arr.length / 2)))
      );


export const pushToDB = async(baeder) =>{
  if(baeder.length !== 0){
    const baederCollection = db.collection("baeder");
    const baederDoc = baederCollection.doc("baederDoc");
    await baederDoc.set({baeder:baeder});
  }
}
export const getBeaderFromDB = async() =>{
    const baederCollection = db.collection("baeder");
    const baederDoc = baederCollection.doc("baederDoc");
    let snapshot = await baederDoc.get();
    return snapshot.data().baeder;
}