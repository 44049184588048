import React, {  } from 'react';
import { connect } from 'react-redux';
import {Grid,Typography} from '@material-ui/core';
import PanoInstance from '../PanoInstance';
import ListView from '../ListView';

function TourView(props){

        return (
            <Grid container direction="row" spacing={1} justify="center" alignContent="center" style={{width:"100%"}}>
                <Grid item xl = {6} lg = {5} md= {5} sm = {12} xs={12}>
                   <ListView/>
                </Grid>
                <Grid item xl = {6} lg = {7} md= {7} sm = {12} xs={12}>
                   <PanoInstance/>
                </Grid>
            </Grid>
               
        );
}
let mapStateToProps = state => ({

});
export default connect(
    mapStateToProps,
)(TourView);