
import {SETACTIVEBAD,SETBAEDER,UPDATEBAD} from './types';
export const setActiveBad = (value) => dispatch => {
    dispatch(
        {
            type: SETACTIVEBAD,
            payload : value
        }
    );      
}
export const setBaeder = (value) => dispatch => {
    dispatch(
        {
            type: SETBAEDER,
            payload : value
        }
    );      
}
export const updateBad = (id,key,value) => dispatch => {
    dispatch(
        {
            type: UPDATEBAD,
            payload : {id:id,key:key,value:value}
        }
    );      
}
