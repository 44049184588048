import {combineReducers} from 'redux';
import panoReducer from './panoReducer';
import loginReducer from "./loginReducer";
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import viewReducer from './viewReducer';
import badReducer from './badReducer';

const rootReducer = combineReducers({
  pano_params : panoReducer,    
  firebase : firebaseReducer,
  firestore : firestoreReducer,
  login : loginReducer,
  view : viewReducer,
  baeder : badReducer
});

export default rootReducer;

        